import React from "react";
import Link from "next/link";
import Image from "next/image";
import {
  statsImages,
  supportersImage,
} from "DataMapper/Refi/LandingPageConstants";
import { trackClick } from "scripts/segment";
import { pageName } from "utils/events_utils";

function SupportersAndStats() {
  return (
    <section className="bg-[#E3E2FF] bg-opacity-25 py-[40px] px-[16px] md:py-[60px]">
      <h2 className="font-bold text-[24px] md:text-[35px] leading-[28px] md:leading-[46px] md:text-center text-[#1b1d6a] mb-[38px] md:mb-[15px]">
        Build your future with Leap
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 md:px-[96px] gap-4">
        <div className="grid grid-cols-2 gap-x-4">
          {statsImages.map((item, index) => (
            <div
              key={`supporters-stats-${index}`}
              className="flex flex-col items-center justify-between min-h-[240px] md:min-h-[278px] max-h-[278px] p-[24px] md:my-[40px] rounded bg-[#fff]"
            >
              <Image height={94} width={94} src={item.image} alt={item.alt} />
              <div className="text-[#443eff] text-[32px] leading-[40px] md:leading-[58px] md:text-[48px] font-bold">
                {item.title}
              </div>
              <div className="text-[14px] md:text-[18px] text-[#1b1d6a] opacity-[.64] leading-5">
                {item.description}
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center justify-between h-[200px] md:h-[278px] md:my-[40px] bg-[#fff] p-[16px] md:p-[40px]">
          <h3 className="text-center mb-8 text-[#1B1D6AA3] text-[14px] md:text-[16px] leading-[21px]">
            We are backed by
          </h3>
          <div className="grid grid-cols-2 gap-4">
            {supportersImage.map((item, index) => (
              <Image
                key={`support-images-${index}`}
                height={74}
                width={244}
                src={item.image}
                alt={item.alt}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="pt-[40px] md:pt-0 md:w-1/4 mx-auto">
        <Link href="#my-eligibility-id" scroll={false}>
          <button
            type="button"
            className="font-bold rounded text-[15px] leading-[20px] py-3 px-[68px] bg-primary text-[#fff] w-full"
            onClick={() => {
              trackClick(pageName.REFI_LANDING_PAGE, {
                widgetName: "Social Proof",
                widgetFormat: "Banner",
                contentName: "Check Eligibility",
                contentFormat: "Button",
              });
            }}
          >
            Check Eligibility
          </button>
        </Link>
      </div>
    </section>
  );
}

export default SupportersAndStats;
